import api from '../../services/payment';
export default function (helper) {
	/**
	 * Generated store module
	 */
	helper.setApi(api);
	const types = helper.types('payment');
	const state = helper.state();
	const getters = helper.getters();
	const actions = helper.actions({
		/**
		 * Get Payment Plans
		 * @returns {Promise}
		 */
		getPlans() {
			helper.log().info(`[Store: Payment]: Get payment plans`);
			return new Promise((resolve, reject) => {
				return api
					.getPlans()
					.then((response) => {
						helper.log().info(`[Store: Payment]: Got payment plans`, response);
						const data = response.data.data;
						resolve(data);
					})
					.catch((error) => {
						helper.log().info(`[Store: Payment]: Error Getting payment plans`, error);
						reject(error);
					});
			});
		},
		/**
		 * Subscription Checkout
		 * @param {any} params
		 * @returns {Promise}
		 */
		checkout(context, params) {
			helper.log().info(`[Store: Payment]: Checkout`, params);
			return new Promise((resolve, reject) => {
				return api
					.checkout(params)
					.then((response) => {
						helper.log().info(`[Store: Payment]: Checkout`, response);
						resolve(response.data.data);
					})
					.catch((error) => {
						helper.log().info(`[Store: Payment]: Checkout Error`, error);
						reject(error);
					});
			});
		},
		/**
		 * Custom Quote for Enterprise Plan
		 * @returns {Promise}
		 */
		customQuote() {
			helper.log().info(`[Store: Payment]: Custom Quote`);
			return new Promise((resolve, reject) => {
				return api
					.customQuote()
					.then((response) => {
						helper.log().info(`[Store: Payment]: Custom Quote`, response);
						resolve(response.data.data);
					})
					.catch((error) => {
						helper.log().info(`[Store: Payment]: Custom Quote Error`, error);
						reject(error);
					});
			});
		},
	}, 'Payment');
	const mutations = helper.mutations({}, types, state);

	return {
		namespaced: true,
		state,
		getters,
		actions,
		mutations
	};
}